import React, { useState, useEffect } from 'react'
import { DataGridPro, GridOverlay } from "@mui/x-data-grid-pro"
import { LinearProgress } from "@mui/material"
import { CustomToolbar, CustomLoadingOverlay } from "../../../components"
import { useParams } from 'react-router-dom'
import { backend_url } from '../../../settings'
import { useTranslation } from 'react-i18next'
import { getParam, onlyUnique, numberFormatter, dateTimeFormatter, checkSize } from "../../../utils/utils"
import { Link } from "react-router-dom"
import { useFetch } from '../../../hooks'
import { useUserPrefs } from "../../UserPreferences/ProvideUserPrefs";
import ListIcon from '@mui/icons-material/List';
import { useNavigate } from 'react-router'
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'


export default function PaymentDetail() {

  const { t } = useTranslation('translation')
  const value_bu = localStorage.getItem('bu_bpx')
  const tokenBpxRequest = localStorage.getItem('token_bpx')

  let paramsHook = useParams()
  const request = useFetch()

  const [rows, setRows] = useState([])
  const [documentKeys, setDocumentKeys] = useState([])
  const [selectionModel, setSelectionModel] = useState([])
  
  const [lines, setLines] = useState([])
  const [status, setStatus] = useState([])
  const [document, setDocument] = useState([])
  const { prefsData, saveUserPrefs } = useUserPrefs()
  const [fontSize, setFontSize] = useState(prefsData.reports_column_size&&prefsData.reports_column_size.ERP_Statuses || 'm');

  
  
  const createUrl = (arg)=>{
    const queryParams = {
      business_unit: value_bu,
      BUSINESS_UNITS: value_bu,
      limit: 100,
      PAYMENT_ID: paramsHook.id,
      VERSION: paramsHook.path
    }
    const obj = {}
    
    arg.map(it=>{
      obj[it] = queryParams[it]
    })
    return getParam(obj).toString()
  }

  useEffect(() => {
    localStorage.setItem("currentUrl", window.location.pathname)

    request.fetchData(
      `${backend_url.payments_list}?${createUrl(['BUSINESS_UNITS', 'PAYMENT_ID'])}`,
      'GET',
      null,
      false,
      tokenBpxRequest
    )
    .then(data => {
    
      setRows(
        data.map((wrap) => {          
          return {
            id: wrap.UNIQUE_ID,
            ...wrap,
          }
        })
      )

    })

    request.fetchData(
      `${backend_url.payments_erp}?${createUrl(['business_unit', 'PAYMENT_ID'])}`,
      'GET',
      null,
      false,
      tokenBpxRequest,
      false
    )
    .then(data => {

      const reference_doc = 
        Object
          .values(data)
          .map(k => k["REFERENCE_DOC_KEY"])
          .filter(onlyUnique)
          .join(", ")

      setDocumentKeys(
        reference_doc
      )

    })

    request.fetchData(
      `${backend_url.payments_status}?${createUrl(['business_unit', 'PAYMENT_ID'])}`,
      'GET',
      null,
      false,
      tokenBpxRequest,
      false
    )
    .then(data => {

      setStatus(
        data.map((wrap) => {
          return {
            id: wrap.STATUS_COUNTERED_ID + "" + wrap.PAYMENT_TYPE,
            ...wrap,
          }
        })
      )

    })

    request.fetchData(
      `${backend_url.payments_erp}?${createUrl(['business_unit', 'PAYMENT_ID'])}`,
      'GET',
      null,
      false,
      tokenBpxRequest,
      false
    )
    .then(data => {
      const filteredData = filterERPDocuments(data);
      setDocument(
        filteredData.map((wrap) => {
          return {
            id: wrap.PAYMENT_ID + wrap.ERP_PAYMENT_DOC_ID,
            ...wrap,
          }
        })
      )

    })

  }, [])

  const handleSelectRow = (ids) => {

    const selectedID = new Set(ids)
    const selectedRowData = rows.filter(item => 
      selectedID.has(item.id.toString())  
    )
    const getLines = selectedRowData.map(wrap => { 
      return wrap.lines.map(item => ({
          id: item.PAYMENT_ID + "" + item.VERSION + "" + item.AO_ID + "" + item.AO_VERSION,
          ACTIVITY_ID: wrap.ACTIVITY_ID,
          ...item,
          })
      )
    })
    setLines(getLines.flat())
    setSelectionModel(ids)
  }

  const columns = [
    { 
      field: "id", 
      headerName: t("Payment.PAYMENT_ID") 
    },
    { 
      field: "AO_ID", 
      headerName: t("Payment.AO_ID"), 
      flex: 1 
    },
    { 
      field: "EXT_REFERENCE", 
      headerName: t("Payment.EXT_REFERENCE"), 
      flex: 1 
    },
    { 
      field: "PAYMENT_TYPE", 
      headerName: t("Payment.PAYMENT_TYPE"), 
      flex: 1 
    },
    { 
      field: "SALES_AREA", 
      headerName: t("Payment.SALES_AREA"), 
      flex: 1 
    },
    { 
      field: "PLAN_ACCOUNT_BP_NR", 
      headerName: t("Payment.PLAN_ACCOUNT_BP_NR"), 
      flex: 1 
    },
    { 
      field: "PLAN_ACCOUNT_BP_CAT", 
      headerName: t("Payment.PLAN_ACCOUNT_BP_CAT"), 
      flex: 1 
    },
    { 
      field: "PAYER", 
      headerName: t("Payment.PAYER"), 
      flex: 1 
    },
    { 
      field: "DOCUMENT_DATE", 
      headerName: t("Payment.DOCUMENT_DATE"), 
      flex: 1,
      renderCell: ({ value }) => dateTimeFormatter(
        value, prefsData.date_format, prefsData.time_format, prefsData.time_zone
      ),
    },
    { 
      field: "POSTING_DATE", 
      headerName: t("Payment.POSTING_DATE"), 
      flex: 1,
      renderCell: ({ value }) => dateTimeFormatter(
        value, prefsData.date_format, prefsData.time_format, prefsData.time_zone
      ),
    },
    { 
      field: "REFERENCE_DOC", 
      headerName: t("Payment.REFERENCE_DOC"), 
      flex: 1 
    },
    { 
      field: "CANCELLED_PAYMENT", 
      headerName: t("Payment.CANCELLED_PAYMENT"), 
      flex: 1,
      renderCell: ({ value }) => {
        if (isNaN(value)) {
          return value
        }
        return numberFormatter(prefsData.decimal_notation).format(value)  
      },
      align: "right"
    },
    {
      field: "ORIGINAL_AMOUNT",
      headerName: t("Payment.ORIGINAL_AMOUNT"),
      flex: 1,
      renderCell: ({ value }) => {
        if (isNaN(value)) {
          return value
        }
        return numberFormatter(prefsData.decimal_notation).format(value)  
      },
      align: "right"
    },
    { 
      field: "ORIGINAL_CURRENCY", headerName: t("Payment.ORIGINAL_CURRENCY"), flex: 1 
    },
    {
      field: "CONFIRMED_AMOUNT",
      headerName: t("Payment.CONFIRMED_AMOUNT"),
      flex: 1,
      renderCell: ({ value }) => {
        if (isNaN(value)) {
          return value
        }
        return numberFormatter(prefsData.decimal_notation).format(value)  
      },
      align: "right"
    },
    { 
      field: "CONFIRMED_CURRENCY", 
      headerName: t("Payment.CONFIRMED_CURRENCY"), 
      flex: 1 
    },
    { 
      field: "CREATED_ON", 
      headerName: t("Payment.CREATED_ON"), 
      flex: 1,
      renderCell: ({ value }) => dateTimeFormatter(
        value, prefsData.date_format, prefsData.time_format, prefsData.time_zone
      ),
     },
    { 
      field: "STATUS", 
      headerName: t("Payment.STATUS"), 
      flex: 1,
      renderCell: ({ value }) => {
        if(!value){
          return ''
        }
        return (
          <div className="MuiDataGrid-cellContent">{t(`Payment.STATUSES.${value}`)}</div>
        )
      }
    },
  ]

  const objBreadcrumb = [
    { label: t("Breadcrumb.home"), link: "/" },
    { label: t("Breadcrumb.payments"), link: "" },
    { label: t("Breadcrumb.myPayments"), link: "/payments/my-payments" },
    { label: t("Breadcrumb.paymentDetail"), link: window.location.pathname },
  ]

  const dataColumns = {
    lines_columns: [
      { 
        field: "VERSION", 
        headerName: t("PaymentLines.VERSION"), 
        flex: 1 
      },
      { 
        field: "AO_ID", 
        headerName: t("PaymentLines.AO_ID"), 
        flex: 1 
      },
      { 
        field: "ACTIVITY_ID", 
        headerName: t("Payment.ACTIVITY_ID"), 
        flex: 1 
      },
      { 
        field: "AO_VERSION", 
        headerName: t("PaymentLines.AO_VERSION"), 
        flex: 1 
      },
      { 
        field: "AE_AO_LINE_ID", 
        headerName: t("PaymentLines.AE_AO_LINE_ID"), 
        flex: 1 
      },
      { 
        field: "EXT_LINE_ID", 
        headerName: t("PaymentLines.EXT_LINE_ID"), 
        flex: 1 
      },
      { 
        field: "ACCR_PRODUCT_ID", 
        headerName: t("PaymentLines.ACCR_PRODUCT_ID"), 
        flex: 1 
      },
      { 
        field: "ORIGINAL_AMOUNT", 
        headerName: t("PaymentLines.ORIGINAL_AMOUNT"), 
        flex: 1,
        renderCell: ({ value }) => {
          if (isNaN(value)) {
            return value
          }
          return numberFormatter(prefsData.decimal_notation).format(value)  
        },
        align: "right"
      },
      { 
        field: "ORIGINAL_CURRENCY", 
        headerName: t("PaymentLines.ORIGINAL_CURRENCY"), 
        flex: 1 
      },
      { 
        field: "CONFIRMED_AMOUNT", 
        headerName: t("PaymentLines.CONFIRMED_AMOUNT"), 
        flex: 1,
        renderCell: ({ value }) => {
          if (isNaN(value)) {
            return value
          }
          return numberFormatter(prefsData.decimal_notation).format(value)  
        },
        align: "right"
      },
      { 
        field: "CONFIRMED_CURRENCY", 
        headerName: t("PaymentLines.CONFIRMED_CURRENCY"), 
        flex: 1 
      },
      { 
        field: "GROUP_NAME", 
        headerName: t("PaymentLines.GROUP_NAME"), 
        flex: 1 
      },
    ],
    erp_statuses: [
      { 
        field: "STATUS_COUNTERED_ID", 
        headerName: t("PaymentStatus.STATUS_COUNTERED_ID"), 
        flex:1 
      },
      { 
        field: "PAYMENT_TYPE", 
        headerName: t("Payment.PAYMENT_TYPE"), 
        flex:1 
      },
      { 
        field: "CUSTOMER_REF_DOC", 
        headerName: t("PaymentStatus.EXT_REFERENCE"), 
        flex: 1 
      },
      { 
        field: "DATE", 
        headerName: t("PaymentStatus.DATE"), 
        renderCell: ({ value }) => dateTimeFormatter(
          value, prefsData.date_format, prefsData.time_format, prefsData.time_zone
        ),
      },
      { 
        field: "ERP_STATUS_TYPE", 
        headerName: t("PaymentStatus.ERP_STATUS_TYPE")
      },
      { 
        field: "ERP_STATUS_NO", 
        headerName: t("PaymentStatus.ERP_STATUS_NO")
      },
      { 
        field: "ERP_STATUS_TEXT", 
        headerName: t("PaymentStatus.ERP_STATUS_TEXT"), 
        flex:2
      },
    ],
    erp_documents: [
      { 
        field: "PAYMENT_ID", 
        headerName: t("PaymentDocument.PAYMENT_ID"), 
        flex:1 
      },
      { 
        field: "PAYMENT_TYPE", 
        headerName: t("Payment.PAYMENT_TYPE"), 
        flex:1 
      },
      { 
        field: "ERP_DOCUMENT_NR", 
        headerName: t("PaymentDocument.ERP_DOCUMENT_NR"), 
        flex:1 
      },
      { 
        field: "FISCAL_YEAR", 
        headerName: t("PaymentDocument.FISCAL_YEAR"),
        flex:1 
      },
      { 
        field: "COMPANY_CODE", 
        headerName: t("PaymentDocument.COMPANY_CODE"), 
        flex:1 
      },
      
    ]
  }  

  const onChangeFontSize = (value)=>{
    setFontSize(value)
    saveUserPrefs({
      ...prefsData,
      reports_column_size:{
        ...prefsData.reports_column_size,
        ERP_Statuses: value
      }
    })
  }

  const filterERPDocuments = (data) => {
  
    const uniqueSet = new Set();
    const filteredData = [];
  
    data.forEach(row => {
      const uniqueKey = `${row.ERP_DOCUMENT_NR}-${row.FISCAL_YEAR}-${row.COMPANY_CODE}`;

      if (!uniqueSet.has(uniqueKey)) {
        uniqueSet.add(uniqueKey);        
        filteredData.push(row);
      }
    });
    
    return filteredData
  };


  return (
    <div className="tableContainer">
      <div className="block-lines">

      <div>
          <DataGridPro
            sx={{
              '& .MuiDataGrid-row': {
                height: `${checkSize(fontSize, 'row_height')}px !important`,
              },
              '& .MuiDataGrid-cell': {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
              },
              "& .MuiDataGrid-main" : {
                height: Math.min((rows.length||1) * checkSize(fontSize, 'row_height') + checkSize(fontSize, 'header_height'), 6 * checkSize(fontSize, 'row_height') + checkSize(fontSize, 'header_height')) + 'px',
                fontSize: checkSize(fontSize, 'font_size')
              },
              "& .MuiCheckbox-root": {
                transform: `scale(${checkSize(fontSize, 'scale')})`
              }
              // "& .MuiDataGrid-footerContainer": {
              //   justifyContent: "flex-start"
              // },
              // "& .MuiDataGrid-selectedRowCount": {
              //   order: 1
              // },
              // "& .MuiDataGrid-rowCount": {
              //   order: 2,
              // },
            }}
            localeText={{
              noRowsLabel: t("Common.noRows")
            }}
            
            // autoHeight
            rows={rows}
            columns={columns}
            disableSelectionOnClick={true}
            disableColumnFilter
            hideFooter={true}
            getRowHeight={() => 'auto'}
            headerHeight={checkSize(fontSize, 'header_height')}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  id: false,
                },
              },
              
            }}
            components={{
              Toolbar: CustomToolbar,
            }}
            componentsProps={{
              toolbar: {
                bread: objBreadcrumb,
                title: t("Title.PaymentDetail", { payment_id: `${paramsHook.id}`}),
                isFontSize: true,
                fontSize: fontSize,
                onChangeFontSize: onChangeFontSize,
              }
            }}

            checkboxSelection
            onSelectionModelChange={handleSelectRow}
            selectionModel={selectionModel}
            experimentalFeatures={{ newEditingApi: true }}
          />

        </div>


        <div style={{ marginTop: '10px' }} >
          <Accordion>
            <AccordionSummary 
              expandIcon={<ArrowRightIcon className='bpx-arrow-icon' />}
              className='bpx-accordion-header'
              >
           
              <h2 className="toolbarTitle">{t("Title.PaymentLines")}</h2>
            </AccordionSummary>
            
            <AccordionDetails>
              <DataGridPro
              sx={{
                '& .MuiDataGrid-row': {
                  height: `${checkSize(fontSize, 'row_height')}px !important`,
                },
                '& .MuiDataGrid-cell': {
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                },
                "& .MuiDataGrid-main" : {
                  height: Math.min((lines.length||1) * checkSize(fontSize, 'row_height') + checkSize(fontSize, 'header_height'), 6 * checkSize(fontSize, 'row_height') + checkSize(fontSize, 'header_height')) + 'px',
                  fontSize: checkSize(fontSize, 'font_size')
                },
                "& .MuiCheckbox-root": {
                  transform: `scale(${checkSize(fontSize, 'scale')})`
                }
              }} 
              rows={lines}
              columns={dataColumns.lines_columns}
              disableSelectionOnClick={true}
              disableColumnFilter={true}
              hideFooter={true}
              getRowHeight={() => 'auto'}
              headerHeight={checkSize(fontSize, 'header_height')}
              localeText={{
                noRowsLabel: t("Common.noRows")
              }}
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    id: false,
                    VERSION: false,
                    AO_VERSION: false
                  },
                },
              }}
              />
            </AccordionDetails>
          </Accordion>
        </div>

        <div style={{ marginTop: '10px' }} >
          <Accordion>
            <AccordionSummary 
              expandIcon={<ArrowRightIcon className='bpx-arrow-icon' />}
              className='bpx-accordion-header'
              >
           
              <h2 className="toolbarTitle">{t("Title.ERPStatus")}</h2>
            </AccordionSummary>
            
            <AccordionDetails>
              <DataGridPro
              sx={{
                '& .MuiDataGrid-row': {
                  height: `${checkSize(fontSize, 'row_height')}px !important`,
                },
                '& .MuiDataGrid-cell': {
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                },
                "& .MuiDataGrid-main" : {
                  height: Math.min((status.length||1) * checkSize(fontSize, 'row_height') + checkSize(fontSize, 'header_height'), 6 * checkSize(fontSize, 'row_height') + checkSize(fontSize, 'header_height')) + 'px',
                  fontSize: checkSize(fontSize, 'font_size')
                },
                "& .MuiCheckbox-root": {
                  transform: `scale(${checkSize(fontSize, 'scale')})`
                }
              }}
              rows={status}
              columns={dataColumns.erp_statuses}
              disableSelectionOnClick={true}
              disableColumnFilter={true}
              hideFooter={true}
              getRowHeight={() => 'auto'}
              headerHeight={checkSize(fontSize, 'header_height')}
              localeText={{
                noRowsLabel: t("Common.noRows")
              }}
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    id: false,
                    VERSION: false,
                    AO_ID: false,
                    AO_VERSION: false
                  },
                },
              }}
              />
            </AccordionDetails>
          </Accordion>
        </div>


        <div style={{ marginTop: '10px' }} className="block-lines-bottom">
          <Accordion>
            <AccordionSummary 
              expandIcon={<ArrowRightIcon className='bpx-arrow-icon' />}
              className='bpx-accordion-header'
              >
           
              <h2 className="toolbarTitle">{t('Title.ERPDocument')}</h2>
            </AccordionSummary>
            
            <AccordionDetails>
              <DataGridPro
              sx={{
                '& .MuiDataGrid-row': {
                  height: `${checkSize(fontSize, 'row_height')}px !important`,
                },
                '& .MuiDataGrid-cell': {
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                },
                "& .MuiDataGrid-main" : {
                  height: Math.min((document.length||1) * checkSize(fontSize, 'row_height') + checkSize(fontSize, 'header_height'), 6 * checkSize(fontSize, 'row_height') + checkSize(fontSize, 'header_height')) + 'px',
                  fontSize: checkSize(fontSize, 'font_size')
                },
                "& .MuiCheckbox-root": {
                  transform: `scale(${checkSize(fontSize, 'scale')})`
                }
              }}
              rows={document}
              columns={dataColumns.erp_documents}
              disableSelectionOnClick={true}
              disableColumnFilter={true}
              hideFooter={true}
              getRowHeight={() => 'auto'}
              headerHeight={checkSize(fontSize, 'header_height')}
              localeText={{
                noRowsLabel: t("Common.noRows")
              }}
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    id: false,
                    VERSION: false,
                    AO_ID: false,
                    AO_VERSION: false
                  },
                },
              }}
              />
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    </div>
  )
}
